.form{
    width: 500px;
    margin: auto;
}
.form_input_felid{
    display: flex;
    justify-content:center;
    align-items:center;
}

.back{
    border: none;
    width: 50px;
    background: #00897b;
    color: #fff;
    height: 50px;
    border-radius: 11px;
    margin: 10px;
}

@media only screen and (max-width: 500px) {

    .form{
        width: 200px;
        margin: auto;
    }
    .back{
        display: none;
    }
  
  }