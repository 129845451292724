@import url('https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap');

 *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* font-family: 'Otomanopee One', sans-serif; */
  font-size: 14px;
 }
 .rtlDir{
    direction:rtl;
  }